import { ChevronRight, MessageSquare } from "lucide-react";
import icono from "../imagenes/icono.png";
import Perfil4 from "../imagenes/Perfil/Perfil4.png";
import { Link } from "react-router-dom";
export default function PostNews() {
  return (
    <div className="bg-white p-6 font-sans">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Left Column */}
          <div className="lg:w-2/3 bg-gray-200 p-6 rounded-lg shadow">
            <div className="mb-6">
              <div className="mt-4 space-y-4">
                {[...Array(3)].map((_, i) => (
                  <p key={i} className="text-sm prompt-regular text-gray-600">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                ))}
              </div>
            </div>
            <div className="mb-6 flex justify-between border-t border-black border-b border-black ">
              <h3 className="text-lg m-2">
                <b className="prompt-semibold">Seguir</b>
                <span className="prompt-regular text-lg ml-2">Política</span>
              </h3>
              <button className="bg-black text-white px-4 py-2 rounded-full text-lg prompt-semibold flex items-center m-2">
                Seguir +
              </button>
            </div>
            <div className="border-t pt-6">
              <div className="flex items-center mb-4">
                <img
                  src={icono}
                  alt="User"
                  className="w-10 h-10 rounded-full mr-3"
                />
                <div>
                  <Link to="perfil" className="no-underline text-black">
                    <p className="prompt-semibold">Jenny Wilson</p>
                  </Link>

                  <p className="text-sm prompt-regular text-gray-500">
                    Periodista
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap gap-2 mb-4 prompt-semibold text-lg">
                {["Relacionados", "ONU", "Elecciones 2024", "IA"].map((tag) => (
                  <span
                    key={tag}
                    className="border-r-2 border-gray-800 text-gray-700 px-2 py-1  text-xs"
                  >
                    {tag}
                  </span>
                ))}
              </div>
              <div className="max-w-2xl mx-auto mt-8 p-4">
                <div className="flex items-start space-x-4">
                  <img
                    src={icono}
                    alt="User"
                    className="w-10 h-10 rounded-full mr-3"
                  />
                  <div className="flex-grow">
                    <p className="prompt-regular mb-2">
                      ¿Qué piensas? Postea un comentario
                    </p>
                    <input
                      type="text"
                      placeholder="Unirse a la conversación"
                      className="w-full px-3 py-2 bg-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                <button className="mt-4  bg-black text-white py-2 rounded-3xl px-4 text-sm prompt-regular">
                  Comentar
                </button>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="lg:w-1/3 ml-8 mt-20">
            <h3 className="text-xl prompt-regular">ESENCIALES DEL DÍA</h3>
            <div className="space-y-4 ">
              {[...Array(5)].map((_, i) => (
                <div key={i} className="flex items-start">
                  <Link to="post">
                    <img
                      src={Perfil4}
                      alt="News"
                      className="w-40 h-28 object-cover mr-3"
                    />
                  </Link>

                  <div>
                    <Link to="post" className="no-underline text-black">
                      <h4 className="prompt-bold text-xl mb-1 ml-3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </h4>
                    </Link>

                    <Link to="perfil" className="no-underline text-black">
                      <p className="text-xs prompt-regular text-gray-500 ml-3">
                        By José Pérez
                      </p>
                    </Link>
                    <p className="text-xs prompt-regular text-red-500 ml-3">
                      Hace 4 horas
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
