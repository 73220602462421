import React from "react";
import news4 from "../imagenes/News/news4.png";
import { Link } from "react-router-dom";
const EssentialItem = ({ title, author, imageUrl, date }) => (
  <div className="flex mb-7">
    <Link to="post">
      <img src={imageUrl} alt={title} className="w-40 h-32 object-cover mr-4" />
    </Link>

    <div>
      <Link to="post" className="no-underline text-black">
        <h3 className="text-base prompt-bold mb-1 ml-3">{title}</h3>
      </Link>
      <Link to="perfil" className="no-underline">
        <p className="text-xs prompt-regular text-black ml-3">By {author}</p>
      </Link>
      <p className="text-sm prompt-regular text-red-500 ml-3">{date}</p>
    </div>
  </div>
);

const EsencialesPolitica = () => {
  return (
    <div className="w-full md:w-1/3">
      <div className="flex justify-between items-center mb-4 pb-2">
        <h2 className="text-xl prompt-regular">
          ESENCIALES DEL DÍA <span className="text-black ml-5">&gt;</span>{" "}
        </h2>
      </div>
      {[1, 2, 3, 4, 5, 6].map((item) => (
        <EssentialItem
          key={item}
          title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
          author="José Pérez"
          date="Hoy a las 11:30 a.m. UTC"
          imageUrl={news4}
        />
      ))}
    </div>
  );
};

export default EsencialesPolitica;
