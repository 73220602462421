import React from "react";
import news1 from "../imagenes/News/news1.png";
import news2 from "../imagenes/News/news2.png";
import foto1 from "../imagenes/LandingPage/foto1.jpg";
import { Link } from "react-router-dom";
const PoliticaSetcion = () => {
  return (
    <section className="container mx-auto my-8 px-4 mt-2">
      <div className="border-t border-black"></div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-2">
        {/* Main Opinion */}
        <div className="lg:col-span-2 ml-8 mt-8">
          <Link to="post">
            <img
              src={foto1}
              alt="Principal Opinión"
              className="max-w-3xl h-fit object-cover mb-4"
            />
          </Link>

          <Link to="post" className="no-underline text-black">
            <h3 className="text-2xl prompt-semibold mb-2 ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </h3>
          </Link>

          <Link to="perfil" className="no-underline">
            <p className="text-black prompt-regular mb-4">By José Pérez</p>
          </Link>
          <p className="text-gray-800 prompt-semibold border-t border-gray-300">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt.
          </p>
        </div>

        {/* Additional Opinions */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          {[1, 2, 3, 4].map((item) => (
            <div key={item} className="mb-4 mt-6">
              <Link to='post' className="no-underline text-black">
              <img
                src={news2}
                alt={`Opinión ${item}`}
                className="w-52 h-28 object-cover mb-2"
              />
              <h4 className="prompt-semibold">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </h4>
              </Link>
              <Link to="perfil" className="no-underline">
                <p className="text-black mb-4 prompt-regular ">By José Pérez</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PoliticaSetcion;
